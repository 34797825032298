<script setup>

</script>

<template>
    <div id="order" :class="[(isOpen) ? 'open' : '']">
        <div @click="closeAction" class="scrim-closer"></div>
        <div class="order-inner">
            <div class="order-inner2">
                <!--
                <div class="bgimg"
                     :style="`background-image: url(` + require('@/assets/images/popup/tt-bg.jpg') + `)`">
                </div>
                -->
                <div @click="closeAction" class="close-button-outer">
                    <div class="close-button"></div>
                </div>
                <div class="order-inner3">
                    <div class="tt">
                        <!--<img src="@/assets/images/home/tt.png"/>-->
                    </div>

                    <div v-if="this.bugsType==='theaters'" id="bugs-theaters" class="bugs">



                    </div>
                    <div v-if="this.bugsType==='vod'" id="bugs-vod" class="bugs">

                        <button class="bug num-1">
                            <a href="https://www.vudu.com/content/browse/details/Puzzle-Box/3536414"
                               target="_blank">
                                <img src="@/assets/images/order/fandango-at-home.png"/>
                            </a>
                        </button>
                        <button class="bug num-1">
                            <a href="https://tv.apple.com/us/movie/puzzle-box/umc.cmc.6fjq3xk89t6w8opzx691r3n82"
                               target="_blank">
                                <img src="@/assets/images/order/apple-tv.svg"/>
                            </a>
                        </button>


                        <!--
                        <button class="bug xnum-1">
                            <a href="https://tv.apple.com/us/movie/invoking-yell/umc.cmc.525kdh43ums1jsad9abwbysr4"
                               target="_blank">
                                <img src="@/assets/images/order/apple-tv.svg"/>
                            </a>
                        </button>

                        <button class="bug xnum-1">
                            <a href="https://www.vudu.com/content/browse/details/Invoking-Yell/3625166"
                               target="_blank">
                                <img src="@/assets/images/order/fandango-at-home.png"/>
                            </a>
                        </button>

                        <button class="bug xnum-1">
                            <a href="https://www.amazon.com/gp/video/detail/B0D2PG66CW/ref=atv_dp_share_cu_r?"
                               target="_blank">
                                <img src="@/assets/images/order/prime.png"/>
                            </a>
                        </button>







                        <button class="bug block">
                            <a href="https://watch.amazon.com/detail?gti=amzn1.dv.gti.0bd64e0b-763d-41dc-9223-0fbfff550edb&territory=US&ref_=share_ios_movie&r=web" target="_blank">
                                <img src="@/assets/images/order/prime.png"/>
                            </a>
                        </button>
                        <button class="bug xnum-1">
                            <a href="https://www.vudu.com/content/browse/details/Subservience/3327441"
                               target="_blank">
                                <img src="@/assets/images/order/fandango-at-home.png"/>
                            </a>
                        </button>
                        <button class="bug comingsoon">
                            <img src="@/assets/images/order/itunes.png"/>
                            <div class="comingsoon2">Coming Soon</div>
                        </button>

                        <button class="bug comingsoon">
                            <img src="@/assets/images/order/amazon.png"/>
                            <div class="comingsoon2">Coming Soon</div>
                        </button>
                        <button class="bug xnum-1">
                            <a href="https://www.amazon.com/Backspot-Devery-Jacobs/dp/B0CW4ZC4M5" target="_blank">
                                <img src="@/assets/images/order/amazon.png"/>
                            </a>
                        </button>
                        <button class="bug xnum-1">
                            <a href="https://tv.apple.com/us/movie/backspot/umc.cmc.6n9zfqyt5eq9msu4ej1qsbhxd"
                               target="_blank">
                                <img src="@/assets/images/order/apple-tv.png"/>
                            </a>
                        </button>
                        <button class="bug xnum-1">
                            <a href="https://www.xfinity.com/stream/entity/7518903800086714112" target="_blank">
                                <img src="@/assets/images/order/xfinity.png"/>
                            </a>
                        </button>
                        <button class="bug xnum-1">
                            <a href="https://www.vudu.com/content/browse/details/Backspot/3150395" target="_blank">
                                <img src="@/assets/images/order/fandango-at-home.png"/>
                            </a>
                        </button>
                        <button class="bug xnum-1">
                            <a href="https://play.google.com/store/movies/details/Backspot?id=bHzAf9a8ccw.P" target="_blank">
                                <img src="@/assets/images/order/google.png"/>
                            </a>
                        </button>
                        <button class="bug xnum-1">
                            <a href="https://ondemand.spectrum.net/movies/27257837/backspot/" target="_blank">
                                <img src="@/assets/images/order/spectrum.svg"/>
                            </a>
                        </button>
                        <button class="bug xnum-1">
                            <a href="https://tv.verizon.com/watch/movie/Backspot/MV021993720000?idType=TMS&qualifiedId=TMS-MV021993720000" target="_blank">
                                <img src="@/assets/images/order/verizon.png"/>
                            </a>
                        </button>
                        <button class="bug xnum-1">
                            <a href="https://www.microsoft.com/en-US/p/_/8D6KGWXX1FX9" target="_blank">
                                <img src="@/assets/images/order/microsoft.png"/>
                            </a>
                        </button>



                         <button class="bug comingsoon">
                            <img src="@/assets/images/order/microsoft.png"/>
                            <div class="comingsoon2">Coming Soon</div>
                        </button>
                        <button class="bug comingsoon">
                            <img src="@/assets/images/order/itunes.png"/>
                            <div class="comingsoon2">Coming Soon</div>
                        </button>

                        <button class="bug comingsoon">
                            <img src="@/assets/images/order/vudu.png"/>
                            <div class="comingsoon2">Coming Soon</div>
                        </button>
                        <button class="bug">
                            <a href="https://tv.apple.com/movie/mind-body-spirit/umc.cmc.22r86hpogqaoxbg340icpqy0i?itsct=tv_box_link&itscg=30200" target="_blank">
                                <img src="@/assets/images/order/apple-tv.png"/>
                            </a>
                        </button>





                        <button class="bug">
                            <a href="https://www.vudu.com/content/browse/details/Mind-Body-Spirit/3111538" target="_blank">
                                <img src="@/assets/images/order/vudu.png"/>
                            </a>
                        </button>

                        <button class="bug">
                            <a href="https://www.microsoft.com/en-us/p/mind-body-spirit/8d6kgwxww0wk?activetab=pivot%3aoverviewtab" target="_blank">
                                <img src="@/assets/images/order/xbox.png"/>
                            </a>
                        </button>

                        <button class="bug comingsoon">
                            <img src="@/assets/images/order/amazon.png"/>
                            <div class="comingsoon2">Coming Soon</div>
                        </button>

                        <button class="bug comingsoon">
                            <img src="@/assets/images/order/google.png"/>
                            <div class="comingsoon2">Coming Soon</div>
                        </button>



                        <button class="bug">
                            <a href="https://www.regmovies.com/movies/hunt-her-kill-her/ho00013977#/" target="_blank">
                                <img src="@/assets/images/order/regal.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.amctheatres.com/movies/hunt-her-kill-her-71905" target="_blank">
                                <img src="@/assets/images/order/amc.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.fandango.com/hunt-her-kill-her-2023-231039/movie-overview?date=2023-03-02"
                               target="_blank">
                                <img src="@/assets/images/order/fandango.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.laemmle.com/film/hunt-her-kill-her#get-tickets"
                               target="_blank">
                                <img src="@/assets/images/order/lamelle.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.readingcinemasus.com/rohnertpark/film/hunt-her-kill-her"
                               target="_blank">
                                <img src="@/assets/images/order/reading.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href="https://www.consolidatedtheatres.com/kaahumanu"
                               target="_blank">
                                <img src="@/assets/images/order/consolidated.png"/>
                            </a>
                        </button>
                        <button class="bug">
                            <a href=" https://lumierecinemala.com/film-hunt-her-kill-her/"
                               target="_blank">
                                <img src="@/assets/images/order/lumiere.png"/>
                            </a>
                        </button>
                        -->

                    </div>
                    <div class="hash">
                        #puzzlebox
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import gh from "@/helpers/gh"

export default {
    name: "OrderBlock",
    components: {

    },
    data() {
        return {
            gh: gh,
            window: window,
            isOpen: false,
        }
    },
    mounted() {
        this.window.addEventListener('keyup', this.escapeButtonPressed)
    },
    computed: {

    },

    methods: {
        openAction(type) {
            this.bugsType = type
            this.isOpen = true
        },
        closeAction() {
            this.isOpen = false
        }
    }
}
</script>