<template>
    <div class="all-buttons stagger2">

        <EmailSignup
            mcDomain="https://lullabymovieofficial.us20.list-manage.com"
            mcUserId="b25b645d846865015940afb4d"
            mcListId="ab49050273"
        ></EmailSignup>

        <a href="https://xyzfilms.com" target="_blank">
            <div id="btn-xyz" class="btn-static">
                <img src="@/assets/images/home/xyz.png"/>
            </div>
        </a>
    </div>
</template>

<script>

import EmailSignup from "@/components/blocks/EmailSignup.vue";

export default {
    name: "AllButtons",
    components: {EmailSignup},
    props: {

    },
    data () {

    },
}
</script>

