<template>
    <div class="email-classy rte no-max-width">
        <div v-if="finalMessage" class="email-classy-inner">
            <div v-html="finalMessage"></div>
        </div>
        <div v-else class="email-classy-inner">
            <div class="email-signup-fields">
                <form :action="actionUrl" :id="mcFormId" method="post" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" :name="hiddenInputName" tabindex="-1" value=""></div>
                    <div class="mc-field-group">
                        <div class="mc-field mc-field-email">
                            <input :class="['required email mce-email', (formErrors.email) ? 'error' : '']"
                                   v-model="formValues.email" @keyup="inputKeyUpHandler" type="email"
                                   placeholder="email address" name="EMAIL">
                        </div>
                        <div class="mc-field mc-field-btn">
                            <div @click="submit" class="btn-carrot full-width">
                                <span>submit</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="errorMessage" id="mc_embed_signup_error" v-html="errorMessage"></div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import $ from "jquery"
//import {gsap, Linear} from 'gsap/all'

export default {
    name: "EmailSignup",
    props: {
        mcDomain: {
            type: String, // use full domain, with https, no trailing slash at end: "https://XXXXXXXXXXX.usXX.list-manage.com"
            required: true,
        },
        mcUserId: {
            type: String,
            required: true, // XXXXXXXXXXX
        },
        mcListId: {
            type: String,
            required: true, // XXXXXXXXXXX
        },

    },
    data () {
        return {
            window: window,
            mcFormId: 'form-' + Date.now(),
            clickedOnce: false,
            formValues: {},
            formErrors: {},
            errorMessage: '',
            finalMessage: ''

        }
    },
    watch: {
        popupOpen() {
            if (this.popupOpen) {
                this.openPopup()
            }
        }
    },
    computed: {
        ...mapState({
            popupOpen: state => state.popupOpen,
        }),
        actionUrl () {
            //return `${this.mcDomain}/subscribe/post-json?u=${this.mcUserId}&id=${this.mcListId}&c=?`
          return `${this.mcDomain}/subscribe/post-json?u=${this.mcUserId}&id=${this.mcListId}&c=?`
        },
        hiddenInputName () {
            return `b_${this.mcUserId}_${this.mcListId}`
        }
    },
    beforeMount () {
        this.reinit()
    },
    mounted () {

    },
    methods: {
        reinit (errorsOnly = false) {
            //errors
            this.formErrors = {
                email: '',
                firstName: '',
                lastName: ''
            }
            this.errorMessage = ''

            //values and final message
            if (!errorsOnly) {
                this.formValues = {
                    email: '',
                    firstName: '',
                    lastName: ''
                }
                this.finalMessage = ''
            }

        },

        inputKeyUpHandler (e) {
            if (e.keyCode === 13) { // enter
                this.submit()
            } else {
                if (this.clickedOnce) {
                    this.doValidate()
                }
            }
        },

        //email signup
        submit: function () {
            this.clickedOnce = true
            const cont = this.doValidate()
            if (cont) {
                this.ajax();
            }
        },

        doValidate () {
            this.clearErrors()
            const email = this.formValues.email
            //const firstName = this.formValues.firstName
            //const lastName = this.formValues.lastName

            let cont = true
            let errorMessages = ""
            //email
            if (!this.isValidEmail(email)) {
                cont = false
                errorMessages += '<p>Please enter a valid email address</p>'
                this.formErrors.email = true
            }

            //show errors
            if (cont === false) {
                this.showError(errorMessages)
            }
            return cont
        },

        clearErrors: function () {
            this.reinit(true)
        },

        showError: function (err) {
            if (err) {
                this.errorMessage = `<p><small>${err}</small></p>`
            } else {
                this.errorMessage = ''
            }
        },

        ajax: function () {
            let data = {
                //FNAME: this.formValues.firstName,
                //LNAME: this.formValues.lastName,
                EMAIL: this.formValues.email,
            }
            const t = this
            $.ajax({
                type: 'post',
                url: this.actionUrl,
                data: data,
                cache: false,
                dataType: 'json',
                contentType: "application/json; charset=utf-8",
                method: 'post',
            }).then(function (response) {
                if (response.result === "error") {
                    let errmsg = response.msg;
                    errmsg = errmsg.toString();
                    if (errmsg.indexOf('is already') > -1) {
                        t.showError('')
                        t.finalStatus()
                    } else {
                        t.showError(`${errmsg}. Please try later.`)
                    }

                } else {
                    t.showError('');
                    t.finalStatus()
                }
                t.formValues = {}
            }.bind(t)).catch(function (error) {
                console.log(error, t)
                t.showError(`An error has occurred.  Please try again later.`)
            }.bind(t))

        },

        finalStatus: function () {
            this.finalMessage = '<p class="success-done">You\'re in.<br>Welcome to the list.</p>'
        },

        isValidEmail: function (val) {
            // eslint-disable-next-line
            const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
            if (reg.test(val) === false) {
                return false
            } else {
                return true
            }
        },
    }
}
</script>
