<template>
    <section id="section-home">
        <div
            :style="`background-image: url(` + require('@/assets/images/home/bg.jpg') + `)`"
            class="abs100 stagger1">
        </div>
        <div id="sizer" ref="sizer">
            <div id="middle" class="abs100">
                <div id="middle2">
                    <img class="tt stagger2" src="@/assets/images/home/tt.png"/>
                    <div class="maintxt stagger2">IN THEATRES NATIONWIDE EASTER 2025</div>


                    <div class="maintxt stagger2 based1a">Based on a True Story</div>
                    <a class="stagger2" href="https://letterboxd.com/film/the-ritual-2025/" target="_blank">
                        <div id="btn-press" class="btn-static">
                            <img src="@/assets/images/home/btn.png"/>
                            <span>Press</span>
                        </div>
                    </a>
                    <a class="stagger2" href="/begone-satan.pdf" target="_blank">
                        <div id="btn-satan" class="btn-static">
                            <img src="@/assets/images/home/btn.png"/>
                            <span>Begone Satan</span>
                        </div>
                    </a>
                    <!--
                    <span class="stagger2" @click="openVideo">
                        <div id="btn-church" class="btn-static">
                            <img src="@/assets/images/home/btn.png"/>
                            <span>The Church</span>
                        </div>
                    </span>
                    -->

                    <div class="maintxt stagger2 based1">learn more</div>
                    <AllButtons></AllButtons>
                </div>
            </div>
        </div>

        <OrderBlock/>
    </section>
</template>

<script>
import gh from "@/helpers/gh"
import OrderBlock from '@/components/blocks/OrderBlock'
import AllButtons from '@/components/blocks/AllButtons'


export default {
    name: "HomePage",
    components: {
        OrderBlock,
        AllButtons
    },
    data() {
        return {
            gh: gh,
            window: window,
            bugsType: null,
        }
    },
    mounted() {
        this.scaleSizer();
        window.addEventListener('resize', this.scaleSizer);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.scaleSizer);
    },
    methods: {
        scaleSizer() {
            const sizer = this.$refs.sizer;
            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;

            const originalWidth = 3000;
            const originalHeight = 2052;
            const elementAspectRatio = originalWidth / originalHeight;

            let scale;
            let adjustedWidth = originalWidth;
            let adjustedHeight = originalHeight;

            if (viewportWidth < 800) {
                // Overcompensate for small widths
                adjustedWidth = originalWidth * 0.42;
                adjustedHeight = adjustedWidth / elementAspectRatio; // Maintain aspect ratio
                scale = viewportWidth / adjustedWidth;
            } else {
                const viewportAspectRatio = viewportWidth / viewportHeight;

                if (viewportAspectRatio > elementAspectRatio) {
                    // Fit to height
                    scale = viewportHeight / originalHeight;
                } else {
                    // Fit to width
                    scale = viewportWidth / originalWidth;
                }

                adjustedWidth = originalWidth;
                adjustedHeight = originalHeight;
            }

            const scaledWidth = adjustedWidth * scale;
            const scaledHeight = adjustedHeight * scale;

            sizer.style.transform = `scale(${scale})`;
            sizer.style.transformOrigin = 'top left';
            sizer.style.width = `${adjustedWidth}px`;
            sizer.style.height = `${adjustedHeight}px`;

            // Center horizontally
            sizer.style.left = `${(viewportWidth - scaledWidth) / 2}px`;

            // Center vertically
            sizer.style.top = `${(viewportHeight - scaledHeight) / 2}px`;
        },
        openVideo() {
            this.gh.openOverlay('video');
        },
    }
}
</script>
<style scoped>
#sizer {
    position: absolute;
    width:3000px;
    height: 2052px;
    top:0;
    left:0;
}
</style>
